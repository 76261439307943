import { useHistory } from "react-router-dom";
import { Button, Input, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";

const GenerateQR = () => {
  const myRef = useRef();
  const [qrText, updateQrText] = useState("");
  const updateQRcode = () => {
    QRCode.toCanvas(myRef.current, qrText, function (error) {
      if (error) console.error(error);
      console.log("success!");
    });
  };

  return (
    <div>
      <center>
        <Typography variant="h4" component="h4">
          Generate your own QR code
        </Typography>
        <Input
          style={{
            fontSize: "30px",
            textAlign: "center",
            marginTop: "100px",
            display: "block",
            width: "300px",
          }}
          onChange={(e) => {
            debugger;
            updateQrText(e.target.value);
          }}
          value={qrText}
        ></Input>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => updateQRcode()}
          style={{
            fontSize: "30px",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          Proceed
        </Button>
        <div>
          <canvas ref={myRef}></canvas>
        </div>
      </center>
    </div>
  );
};

export default GenerateQR;
